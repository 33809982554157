<template lang="">
  <div>
    <TabView>
      <TabPanel v-for="categoria in categorias" :key="categoria.nome">
        <template #header>
          <div class="flex justify-content-center align-items-center gap-1">
            <i style="margin-right: 5px" :class="categoria.categoriaIcone" />
            <span>
              {{ categoria.categoriaNome }}
            </span>
          </div>
        </template>
        <ProtocoloDigitalServico :categoria="categoria" />
      </TabPanel>
    </TabView>
  </div>
</template>
<script>
import CategoriaService from '@/service/CategoriaService'
import ProtocoloDigitalServico from '@/views/servidor/protocolo-digital/ProtocoloDigitalServicoList.vue'
import { matriculaStore } from '@/stores/matricula'

export default {
  components: {
    ProtocoloDigitalServico,
  },

  setup() {
    const store = new matriculaStore()
    return { store }
  },

  data() {
    return {
      categorias: [],
      matriculaId: this.store.matriculaSelecionada.id,
    }
  },

  created() {
    this.categoriaService = new CategoriaService(this.$http)
    this.carregarServicos()
  },

  mounted() {},

  methods: {
    carregarServicos() {
      this.categoriaService
        .listarCategorias(this.matriculaId)
        .then((res) => {
          this.categorias = res
        })
        .catch((err) => {
          this.showToastErro(err)
        })
    },

    showToastErro(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.limpar()
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style lang=""></style>
