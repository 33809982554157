<template lang="">
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title> {{ categoria.categoriaNome }}</template>
        <template #content>
          <DataTable
            stripedRows
            :paginator="true"
            :value="categoria.servicos"
            :loading="loading"
            :rows="5"
            :filters.sync="filtros"
            :globalFilterFields="['nome']"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} serviços"
            responsiveLayout="stack">
            <template #empty> Nenhum serviço encontrado. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2 mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filtros['global'].value"
                    placeholder="Pesquisar"
                    style="width: 100%" />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Limpar"
                  class="p-button-outlined mb-2"
                  @click="limparFiltro" />
              </div>
            </template>
            <Column field="nome" header="Titulo" sortable>
              <template #body="{ data }"
                ><div class="flex justify-content-between flex-wrap">
                  <div class="flex align-items-center justify-content-start">
                    <label>{{ data.nome }}</label>
                  </div>
                  <div class="flex align-items-center justify-content-end">
                    <Button
                      v-tooltip.left="'Ver Serviço'"
                      label="Ver Serviço"
                      :placeholder="'Ver Serviço'"
                      class="p-button p-button-submit"
                      @click="acessarDetalhesServico(data)" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
export default {
  props: {
    categoria: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      filtros: {},
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.loading = true
    this.initFiltros()
  },

  mounted() {},

  methods: {
    limparFiltro() {
      this.filtros['global'].value = ''
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
      this.loading = false
    },

    acessarDetalhesServico(data) {
      this.$router.push({
        name: 'protocoloDigitalDetalhe',
        params: { id: data.id },
      })
    },
  },
}
</script>

<style>
.right-align {
  text-align: right;
}
</style>
